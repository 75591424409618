export default {
    list: {
        title: "stock_history",
        icone: "history",
        class: "flex-1 mt-1 px-3 items-center",
        name: 'stockHistories',
        options: {
            defaultPageSize: 5,
            actions: {
                isActive: false,
                name: "Actions",
                buttons: [{
                    text: "",
                    icon: "",
                    link: '',
                    color: 'primary'
                }],
            }
        },
        columns: [{
                name: "user",
                elements: [{
                    items: [{
                        class: "ouka",
                        name: "user",
                        elements: [{
                            items: [
                                {
                                    name: "fullName",
                                    icone: "contacts"
                                },
                                {
                                    name: "type",
                                    class: "font-light text-gray-700 uppercase",
                                }
                            ]
                        }]

                    }, ],
                }, ]
            },
            {
                name: "product",
                elements: [{
                    items: [{
                        class: "ouka",
                        name: "product",
                        elements: [{
                            items: [{
                                    name: "picture",
                                    class: "font-light text-gray-700",
                                },
                                {
                                    name: "name",
                                    class: "font-light text-gray-700",
                                }
                            ]
                        }]

                    }, ],
                }, ]
            },
            {
                name: "quantity",
                elements: [{
                    items: [{
                        class: "",
                        name: "quantity",
                        history: true,
                        type: "text",
                    }, ],
                }, ]
            },
            {
                name: "Warehouse",
                elements: [{
                    items: [{
                        class: "ouka",
                        name: "warehouse",
                        elements: [{
                            items: [
                                {
                                    name: "name",
                                   // icone: "contacts"
                                },
                               
                            ]
                        }]

                    }, ],
                }, ]
            },
            {
                name: "type",
                elements: [{
                    items: [{
                        class: "",
                        name: "type",
                        type: "text",
                    }, ],
                }, ]
            },
            {
                name: "date",
                class: "",
                elements: [{
                    items: [{
                        class: "",
                        name: "date",
                        date: true,
                        type: "text",
                    }],
                }, ]
            },
            {
                name: "reason",
                class: "",
                elements: [{
                    items: [{
                        class: "",
                        name: "reason",
                        type: "text",
                    }],
                }, ]
            },
        ],
        actions: {
            name: "actions",
            width: "10px",
            class: "rounded-full flex justify-center items-center focus:outline-none w-8 h-8 mr-3 mt-1",
            elements: [{
                icone: "delete",
                color: "danger",
                role: true,
                key: 'delete',
                tooltip: "Delete",
            }],
        },
        filters: {
            name: 'products',
            elements: {
                search: {
                    options: {
                        icon: "search",
                        label: "search",
                        class: "",
                        hint: "search_product"
                    },
                    value: null,
                    type: "search",
                    validate: {
                        trim: true,
                        required: false,
                        minlength: 0,
                    }
                },
                champs: [],
            },
        },
    },
}